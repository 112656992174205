import appConfig from "./appConfig";

const apiConfig = {
  publicURL: appConfig.host,
  baseURL: (
    appConfig.host +
    "/" +
    appConfig.prefix +
    "/" +
    appConfig.version +
    "/"
  ).replace(/([^:]\/)\/+/g, "$1"),

  //  ------------------------ Admin Dashboard -----------
  adminDashboardGetTotalSalaryOt: "admin-dashboard/get-total-salary-ot",
  adminDashboardHubstaffFetchRequest:
    "admin-dashboard/get-pending-fetch-request",
  adminPendingEmployeeLeave: "admin-dashboard/get-pending-leave",
  adminEmployeeShortLeave: "admin-dashboard/get-short-leave",
  adminDashboardCalendarData: "admin-dashboard/get-calendar-data/:month",
  adminDashboardOverTimeRequest: "admin-dashboard/get-pending-overtime-request",
  adminPendingAddTimeRequest: "admin-dashboard/get-pending-add-time-request",
  adminPendingGetTask: "admin-dashboard/get-tasks",

  //  -------------- company-details -------------------
  companyDetailsInfomation: "company-details",
  listCompanyDetails: "list/company-details",
  updateCompanyDetailsInfomation: "company-details/:company_details_id",
  toggleCompanyDetails:
    "company-details/:company_details_id/changeActiveCompany",
  // ------------------------ Login ----------------------
  login: "login",

  // ------------------------ Password -------------------
  forgotPassword: "forgot-password/:email",
  resetPassword: "reset-password/:code",

  // ------------------------ Verify Url -----------------
  verifyUrl: "verify-url/:code",

  // ------------------------ User -----------------
  userData: "user",
  userRoleList: "list/user_role",
  toggoleActive: "user/:user_id/toggleActive",
  toggleCanLogin: "user/:user_id/toggleCanLogin",
  userLogin: "user_login/:user_id",
  employeeProfile: "employee/profile/:user_id",
  userList: "user",
  userProfile: "user/profile",
  changePassword: "user/password",
  changePinOldPin: "employee/change-old-pin",
  resetPasswordEmployee: "user/user_reset_password/:user_id",
  employeSalaryRelatedData: "employee/get-salary-related-data",
  employeSalaryIncrementData: "employee/get-salary-increment",

  // ------------------------ Role Permission -------------------
  rolePermision: "user_role/:role_id",
  role: "user_role",
  permision: "permission",

  // ------------------------ Employee -----------------
  employeeData: "employee",
  toggleCanOvertime: "employee/:employee_id/toggleCanOvertime",
  toggleCanApplyPrivious:
    "employee/:employee_id/toggleCanApplyPreviousApplyLeave",
  toggleConsiderIdleTime: "employee/:employee_id/toggleConsiderIdleTime",
  toggleCanUpdateDetails: "employee/:employee_id/toggleCanUpdateDetails",
  toggleShowOnSummaryDashboard:
    "employee/:employee_id/toggleShowOnSummaryDashboard",
  toggleApplyPrevAddTimeRequest:
    "employee/:employee_id/toggle-can-apply-prev-date-time-request",
  startOfficeMeeting: "meeting/start-meeting/:office_meeting_id",

  employeeUpdate: "employee/:employee_id",
  syncEmployee: "employee/sync",
  employee_profile: "employee/employee_profile",
  getEmployeeBirthDay: "employee/get-birthday",
  listEmployee: "list/employees",
  syncEmployeeProject: "hubstaff/projects/sync",
  employeePermissionSwitch: "employee/employee_permission_switch",

  // --------------------- Bank Details -------------------
  companyBankDetails: "company-bank-details",
  companyBankDetailsToggleActive:
    "company-bank-details/:bank_details_id/toggleActive",
  deleteCompanyBankDetails: "company-bank-details/:bank_details_id",
  listCompanyBankDetails: "list/company-bank-details",

  //  --------------- Employee Bank Details ----------------------

  employeeBankDetails: "employee-bank-details",
  editemployeeBankDetails: "employee-bank-details/:bank_details_id",
  toggleEmployeeBankDetails:
    "employee-bank-details/:bank_details_id/changeActiveBankDetail",
  employeeBankDetailsActive: "employee-bank-details/active",

  // ------------------------ Bank Wise Employee Salary Generate -----------------
  bankWiseEmployeeSalalryGenerate: "bank-wise-employee-salary/generate",
  bankWiseEmployeeSalatyAssign: "bank-wise-employee-salary/assign",

  // ------------------------ bank Wise Salary Info -----------------
  bankWiseSalaryInfo: "salary/get-bank-wise-salary-info",

  // ------------------------ bank Wise Salary Info -----------------
  letterFormat: "letter-format-content",
  letterFormatCategoryWise: "letter-format-content/category-wise-tags",
  deleteLetterFomrat: "letter-format-content/:letter_format_content_id",
  fetchLetterEmployee: "employee/fetch-letter",

  // ------------------------ Hubstaff Fetch Request -----------------
  hubStaffFetchRequest: "hubstaff-fetch-request",
  hubStaffFetchRequestById: "hubstaff-fetch-request/fetch/:fetch_request_id",
  hubStaffFetchRequestAll: "hubstaff-fetch-request/fetch",

  // ------------------------ Employee Leave -----------------
  employeeLeave: "employee_leave",
  employeeLeaveId: "employee_leave/:employee_leave_id",
  changeStatusleave: "employee_leave/leave_status/:employee_leave_id",
  employeePendingLeaveCount: "employee_leave/count-pending-leave",
  employeeLeaveCancel: "employee_leave/cancel_leave/:employee_leave_id",
  employeeShortLeave: "employee-short-leave",
  employeeShortLeaveInformalId:
    "employee-short-leave/:employee_informal_leave_id",
  getUpcomingLeaves: "employee_leave/get-upcoming-leaves",
  adminGetUpcomingLeaves: "admin-dashboard/get-upcoming-leaves",

  // ------------------------ Employee Attendance -----------------
  attendance: "attendance",
  addRevokeTime: "attendance/add-remove-time",
  attendancePenalty: "attendance/penalty",
  appSettingAttendanceKey: "app_settings/:key",

  // ------------------------ Employee Salary -----------------
  employeeSalary: "salary",
  employeeSalaryStatus: "salary/status/:employee_salary_id",
  generateExcelFileDownload: "salary/:month/generate-excel-files",
  employeeChangeBankAccount: "salary/:employee_salary_id/change-bank-account",

  // --------------- Employee Deposit ---------------
  deposit: "deposit",
  deposit_master_id: "deposit/:employee_deposit_master_id",
  deposit_bulkCreate: "deposit/bulkCreate",

  // ---------------------- Employee Over Time Approval -----------------
  overtTimeApproval: "overtime-approval",
  overtTimeApprovalById: "overtime-approval/:overtime_approval_id",

  // ------------------------ Leave Master -----------------
  leaveMaster: "leave_master",
  leaveMasterId: "leave_master/:leave_master_id",
  leaveMasterCarryForward: "leave_master/carry-forward/:leave_master_id",

  // ------------------------ Calendar -----------------
  event: "event",
  holiday: "holiday",
  month_working_time: "month_working_time",

  // ------------------------ Salary Incremenet -----------------
  salary_increment: "salary_increment",
  find_last_increment_date: "salary_increment/find_last_increment_date",

  // ------------------------ App Setting -----------------
  updateAppSetting: "app_settings/update",
  appSetting: "app_settings",
  appSettingUploadFile: "app_settings/upload-file",

  // ------------------------ Change Pin Number -----------------
  employeeChangeSecretPin: "employee/change-secret-pin",
  employeeChangeValidPin: "employee/check-valid-pin",

  // ------------------------ Change Pin Number -----------------
  letterHistory: "letter-history",
  letterHistoryById: "letter-history/:letter_history_id",
  letterHistoryGenerateLetter: "letter-history/generate-letter",

  // ------------------------ Hubstaff Activities -----------------
  hubstaff: "hubstaff",
  hubstaffActivities: "hubstaff/activities",
  urlActivities: "hubstaff/url_activities",
  application_activities: "/hubstaff/application_activities",
  refresh_token: "hubstaff/refreshToken",
  idleTime: "hubstaff/idle_time",
  projects: "hubstaff/projects",
  projectsWiseHubstaff: "hubstaff/project-wise/:project_id",
  toggleProjectIsActiveSwitch: "hubstaff/projects/:project_id/toggle-active",
  toggleProjectUpdateStatus: "hubstaff/projects/:project_id/update-status",
  projectNameEmployeeDataWise:
    "hubstaff/project-wise/:project_id/unique-employees",

  toggleProjectPriority: "hubstaff/projects/:project_id/update-priority",
  listProjectUsers: "list/users",
  hubstaffId: "hubstaff/activities/:hubstaff_activities_id",
  hubstaff_activities_bulk_upload: "hubstaff/activities",
  hubstaff_url_activities_bulk_upload: "hubstaff/url_activities/:date",
  hubstaff_application_activities_bulk_upload:
    "hubstaff/application_activities/:date",
  hubstaff_idle_time_recover: "hubstaff/idle_time/recover/:idle_time_id",
  hubstaff_idle_time_recover_by_per: "hubstaff/idle_time/percentage/recover",
  hubstaff_project_id: "hubstaff/projects/:project_id",
  hubStaffPenaltyActivities: "hubstaff/penalty/:hubstaff_activities_id",
  hubstaffResetData: "hubstaff/activities/reset-all/:hubstaff_activities_id",
  listProjects: "list/projects",
  hubstaffActivitesInDepth: "hubstaff/activities/in-depth",
  urlActivitesInDepth: "hubstaff/url_activities/in-depth",

  // ------------------------ Employee Projects -----------------
  employeeProjects: "employee-project/project/:project_id",
  employeeProjectsNotAssign: "employee-project/employee-not-assign/:project_id",
  employeeProjectCreate: "employee-project",
  deleteEmployeeProject: "employee-project/:employee_project_id",
  employeeProjectReportSummary: "employee-project/employee/:employee_id",
  employeeProjectEmployeeReportSummary: "employee-project/employee",

  // ------------------------ Punch Activities -----------------
  punch: "punch",
  punchId: "punch/:id",
  punchingId: "punch/:punching_id",
  punch_bulk_upload: "punch/bulk_upload",
  punch_export: "punch/export_punching_data/:punching_id",
  punchCreateFixedRecode: "punch/createFixedPunchingRecord",
  punchingPenaltyActivities: "punch/penalty/:punching_activities_id",

  // --------- Report Summary -------------
  addReport: "report",
  reportImage: "report/image",
  reportTimeSolt: "report/timeslot",
  adminReportTimeSolt: "report/admin/timeslot",
  reportEmployee: "report/:employee_report_id",
  reportDashboard: "report/dashboard",

  // --------------- Overtime ---------------

  overtime: "overtime",
  overtimeId: "overtime/:overtime_request_id",
  overtimeRequest: "overtime/request/:overtime_request_id",
  toggleCanAddPervOT: "employee/:employee_id/toggle-can-add-prev-ot",

  // --------------- Notifications ---------------
  notifications: "notifications",
  remove_notification: "notifications/:notification_id",
  notifications_clear_all: "notifications/clear-all",
  notifications_read: "notifications/read/:notification_id",
  notifications_unread: "notifications/unread-notifications",
  bank_income_statements: "bank-income-statements",
  bank_income_bulk_create: "bank-income-statements/bulk-create",

  // --------------- Rules And Regulations ---------------
  rulesAndRegulations: "rules-and-regulation",
  toggleRulesAndRegulations: "rules-and-regulation/:rule_id/toggleActive",
  updateRulesAndRegulations: "rules-and-regulation/:rule_id",
  getAllRulesCategories: "rules-and-regulation/get-all-categories",

  // --------------- Rules And Regulations ---------------
  meetingDashboard: "meeting",
  closeMeetingDashboard: "meeting/close-meeting/:office_meeting_id",
  deleteMeetingDashboard: "meeting/:office_meeting_id",
  forceStopMeetingDashboard: "meeting/force-close-meeting/:office_meeting_id",
  meetingProjectAssigned: "meeting/employee-project-assigned/:employee_id",

  // --------------- Add Time Request ---------------
  addTimeRequest: "add-time-request",
  editaddTimeRequest: "add-time-request/:add_time_request_id",
  adminDashboardEditTimeRequest:
    "add-time-request/update-status/:add_time_request_id",
  monthWorkingDayTime: "month_working_time/working-days",

  // --------------- Project File Upload ---------------
  projectFileUpload: "hubstaff/projects/:project_id/file-upload",
  deleteProjectFileUpload:
    "hubstaff/projects/:project_file_upload_id/file-upload",

  //  ----------- Task Management --------------
  getAllTask: "task",
  deleteTaskById: "task/:id",
  taskIdStatus: "task/:id/status",
  taskSummaryWidgets: "task/task-summary",
  taskIdAssing: "task/:id/assign",
  taskProjectBoard: "task/:project_id/task-boards",
  taskUpdatePriority: "task/:id/update-priority",
  taskFileUpload: "task/:task_id/file-upload",
  deleteTaskFileUpload: "task/:task_file_upload_id/file-upload",
  reportSummaryProjectWiseTask: "task/:project_id/tasks",
  currentTaskSlider: "task/current-task",

  //  ----------- Currency --------------
  currencyMaster: "currency-master",
  toggleCurrencyMasterId: "currency-master/:currency_master_id/toggleActive",
  listCurrency: "list/currency-list",

  //  ----------- Analysis Dashboard --------------
  analysisWidgets: "analysis/widgets",
  analysisMonthWiseSalary: "analysis/month-wise-salary",
  analysisEmployeeDesignation: "analysis/employee-designation",
  analysisTotalLeaves: "analysis/total-leaves",
  analysisSalarySlab: "analysis/slab-wise-employee-salary",
  analysisBankWiseSalary: "analysis/bank-wise-salary",
  analysisActivityHeatMap: "analysis/:employee_id/activity-heat-map",
  analysisDaysActivity: "analysis/hubstaff-activity",
  analysisTopUrlsVisit: "analysis/top-urls-visit",
  analysisTopApplicationUsed: "analysis/top-application-used",
  analysisPaymentPendingProject: "analysis/payment-pending-project",
  analysisUpcomingIncrements: "admin-dashboard/upcoming-increments",
  analysisPaymentSummary: "analysis/payment-summary",

  //  ------  Project Dashboard ( Widgets ) -------
  hubstaffTotalProjects: "hubstaff/projects/total-projects",
  hubstaffTotalPaymentSummary: "hubstaff/projects/payment-summary/:currency",
};

export default apiConfig;
