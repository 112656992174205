//  ------------------ Gender -------------------

export const EmployeeGender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const LetterFormatCategories = [
  { label: "Experience Letter", value: "experience_letter" },
  { label: "Offer Letter", value: "offer_letter" },
  { label: "Joining Letter", value: "joining_letter" },
  { label: "Letter Of Recommendation", value: "letter_of_recommendation" },
];
export const LetterFormatCategoriesNotes = [
  { label: "Experience Letter", value: "experience_letter" },
  { label: "Letter Of Recommendation", value: "letter_of_recommendation" },
];
export const GenrateLetterFormatCategories = [
  { label: "Offer Letter", value: "offer_letter" },
  { label: "Joining Letter", value: "joining_letter" },
  { label: "Letter Of Recommendation", value: "letter_of_recommendation" },
];
export const HubstaffFetchRequestStatus = [
  { label: "Pending", value: "pending" },
  { label: "Auto", value: "auto" },
  { label: "Manually", value: "manually" },
];
export const EmployeeInformalLeave = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Reject", value: "reject" },
];
export const HubstaffAddTimeRequestStatus = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Reject", value: "reject" },
];
export const EmployeeProjectTypes = [
  { label: "Hubstaff", value: "hubstaff" },
  { label: "Manually", value: "manually" },
];

export const isActiveDeactiveRulesAndRegulations = [
  { label: "Active", value: true },
  { label: "Deactive", value: false },
];
export const meetingStatus = [
  { label: "Pending", value: "pending" },
  { label: "On Going", value: "ongoing" },
  { label: "Closed", value: "closed" },
];
export const projectStatus = [
  { label: "Pending", value: "pending" },
  { label: "Quote Given", value: "quote_given" },
  { label: "Approved", value: "approved" },
  { label: "In Progress", value: "in_progress" },
  { label: "Developed", value: "developed" },
  { label: "In Testing", value: "in_testing" },
  { label: "Tested", value: "tested" },
  { label: "Submitted", value: "submitted" },
  { label: "Done", value: "done" },
];
export const billingType = [
  { label: "Hourly", value: "hourly" },
  { label: "Fixed", value: "fixed" },
];
export const activeDeactiveProject = [
  { label: "Active", value: "true" },
  { label: "Deactive", value: "false" },
];
export const projectOldNew = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];
export const projectPriority = [
  { label: "Low", value: "low" },
  { label: "Medium", value: "medium" },
  { label: "High", value: "high" },
];
export const taskManagementStatus = [
  { label: "Pending", value: "pending" },
  { label: "In Progress", value: "in_progress" },
  { label: "Pause", value: "pause" },
  { label: "Done", value: "done" },
];
export const isActiveCurrency = [
  { label: "Active", value: "true" },
  { label: "Deactive", value: "false" },
];
export const useRoleInProject = [
  { label: "Admin", value: "Admin" },
  { label: "Employee", value: "Employee" },
  { label: "Leader", value: "Leader" },
];
export const overTimeRequestStatus = [
  { label: "Pending", value: "pending" },
  { label: "Overtime Accepted", value: "overtime_accepted" },
  { label: "Overtime Rejected", value: "overtime_rejected" },
  { label: "Admin Applied", value: "admin_applied" },
];
export const ProjectAmountStatus = [
  { label: "Pending", value: "pending" },
  { label: "Partial Paid", value: "partial_paid" },
  { label: "Paid", value: "paid" },
];
export const employeeActiveAllSwitches = [
  { label: "Can Add Prev Time Req", value: "can_add_prev_time_req" },
  { label: "Can Add Prev Ot", value: "can_add_prev_ot" },
];
// ----- AllPermissions -----
export const AllPermissions = [
  { id: 0, name: "dashboard" },
  { id: 1, name: "employee_attendance" },
  { id: 2, name: "leave_master" },
  { id: 3, name: "punch" },
  { id: 4, name: "refresh_token" },
  { id: 5, name: "overtime_approval" },
  { id: 6, name: "employee" },
  { id: 7, name: "office_meeting" },
  { id: 8, name: "hubstaff_project" },
  { id: 9, name: "employee_project" },
  { id: 10, name: "holiday" },
  { id: 11, name: "event" },
  { id: 12, name: "deposit_master" },
  { id: 13, name: "salary_increment" },
  { id: 14, name: "rules" },
  { id: 15, name: "employee_salary" },
  { id: 16, name: "sync_project" },
  { id: 17, name: "view_as_employee" },
  { id: 18, name: "month_working_time" },
  { id: 19, name: "sync_employee" },
  { id: 20, name: "employee_report" },
  { id: 21, name: "hubstaff" },
  { id: 22, name: "permission" },
  { id: 23, name: "employee_informal_leave" },
  { id: 24, name: "employee_leave" },
  { id: 25, name: "app_setting" },
  { id: 26, name: "company_bank_details" },
  { id: 27, name: "bank_wise_employee_salary" },
  { id: 28, name: "letter_generate" },
  { id: 29, name: "user_role" },
  { id: 30, name: "company_details" },
  { id: 31, name: "user" },
  { id: 32, name: "overtime_request" },
  { id: 33, name: "bank_income_statements" },
  { id: 34, name: "add_time_request" },
  { id: 35, name: "task" },
  { id: 36, name: "currency_master" },
  { id: 37, name: "analysis" },
];
