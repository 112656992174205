import { useState, useEffect } from "react";
import _, { isArray, reject } from "lodash";
import { toast } from "react-hot-toast";
import { appConfig } from "../configs";
import moment from "moment";
import avatarImg from "../assets/images/users/noimg.jpg";

export const isEmpty = (value) => {
  if (value == null || value === "null") {
    return true;
  }
  if (typeof value == "object") {
    return Object.keys(value).length === 0;
  }
  return (
    (isArray(value) && value.length === 0) ||
    value === undefined ||
    value === "undefined" ||
    value == null ||
    value === ""
  );
};

export const toaster = {
  error: (message, config = {}) => {
    toast.error(message);
  },
  success: (message, config = {}) => {
    toast.success(message);
  },
};

export const isAuth = () => {
  return (
    false == isEmpty(window.localStorage.getItem(appConfig.localStorage.token))
  );
};

export const getTotalWeeksDates = (month) => {
  let _month = moment(month);
  let stateDate = moment(_month).startOf("month");
  let endDate = moment(_month).endOf("month");

  let lastDateOfMonth = moment(_month).endOf("month");

  let weeks = [];

  for (let i = 0; i < 10; i++) {
    if (i != 0) {
      stateDate = stateDate.add(1, "weeks").startOf("isoWeek");
    }

    let isLastDateOfMonth = moment(
      stateDate.clone().endOf("isoWeek")
    ).isSameOrAfter(lastDateOfMonth);

    weeks.push({
      start_date: stateDate.format("YYYY-MM-DD"),
      end_date: isLastDateOfMonth
        ? endDate.clone().format("YYYY-MM-DD")
        : stateDate.clone().endOf("isoWeek").format("YYYY-MM-DD"),
    });

    if (isLastDateOfMonth) {
      break;
    }
  }

  return weeks;
};

export const getImageUrl = (url) => {
  if (isEmpty(url)) {
    return avatarImg;
  }
  const isAbsoluteUrl = /^(?:\w+:)\/\//.test(url);
  if (isAbsoluteUrl) {
    return url;
  } else {
    return `${appConfig.host}${url}`.replace(/([^:]\/)\/+/g, "$1");
  }
};

//  -------------------- getInitials ---------------------
const getInitials = (firstName, lastName) => {
  if (!firstName || !lastName) return "";
  return `${firstName[0]}${lastName[0]}`;
};
//  -------------------- getProfileImage ---------------------
export const getProfileImage = (profile, firstName, lastName) => {
  if (isEmpty(profile) && isEmpty(firstName) && isEmpty(lastName)) {
    return avatarImg;
  }
  const initials = getInitials(
    firstName?.toUpperCase(),
    lastName?.toUpperCase()
  );
  const placeholderUrl = `https://via.placeholder.com/150?text=${initials}`;
  // const placeholderUrl = `https://via.placeholder.com/150/405189/FFFFFF?text=${initials}`;

  if (!profile) {
    return placeholderUrl;
  }

  const isAbsoluteUrl = /^(?:\w+:)\/\//.test(profile);
  const profileUrl = isAbsoluteUrl
    ? profile
    : `${appConfig.host}${profile}`.replace(/([^:]\/)\/+/g, "$1");

  const img = new Image();
  img.src = profileUrl;

  img.onerror = () => {
    img.src = placeholderUrl;
  };

  return img.src;
};

//  -------------------- handleImageError  ( OnError ) -------------------
export const handleImageOnError = (event, firstName, lastName) => {
  event.target.src = getProfileImage(null, firstName, lastName);
};

export const imgObjToBase64 = (imgObj) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(imgObj);
  });
};

export const showErrorToaster = (err) => {
  if (err?.error?.message) {
    toaster.error(err?.error?.message);
  } else if (err?.errors?.message?.message) {
    toaster.error(err?.errors?.message?.message);
  } else if (err?.errors?.message?.code) {
    toaster.error(err?.errors?.message?.error);
  } else if (err?.errors?.message) {
    toaster.error(err?.errors?.message);
  } else if (err?.errors) {
    if (Array.isArray(err?.errors)) {
      err?.errors.forEach((error) => {
        toaster.error(error);
      });
    } else if (typeof err.errors === "string") {
      toaster.error(err?.errors);
    } else {
      Object.values(err?.errors).forEach((error) => {
        toaster.error(error);
      });
    }
  } else {
    console.error(err);
  }

  // if (err?.errors?.message) {
  //   toaster.error(err?.errors?.message);
  // } else if (err?.errors) {
  //   Object.values(err?.errors).map((error) => {
  //     toaster.error(error);
  //   });
  // } else {
  //   console.error(err);
  // }

  //  else if (err?.errors) {
  //   Object.values(err?.errors).map((error) => {
  //     HELPER.toaster.error(error);
  //   });
  // }
};

export const getHours = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const pad = (value) => (value < 10 ? `0${value}` : value);

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

export const getHoursMinute = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const pad = (value) => (value < 10 ? `0${value}` : value);

  return `${pad(hours)}:${pad(minutes)}`;
};

export default function getDateDifference(startDateStr, endDateStr) {
  const start = moment(startDateStr, "DD-MM-YYYY");
  const end = moment(endDateStr, "DD-MM-YYYY").add(1, "day");

  if (start.isValid() && end.isValid()) {
    const years = end.diff(start, "years", true);
    start.add(Math.floor(years), "years");

    const months = end.diff(start, "months", true);
    start.add(Math.floor(months), "months");

    const days = end.diff(start, "days", true);

    let result = "";
    if (Math.floor(years) > 0) {
      result += `${Math.floor(years)} year${
        Math.floor(years) !== 1 ? "s" : ""
      } `;
    }
    if (Math.floor(months) > 0) {
      result += `${Math.floor(months)} month${
        Math.floor(months) !== 1 ? "s" : ""
      } `;
    }
    if (Math.floor(days) > 0) {
      result += `${Math.floor(days)} day${Math.floor(days) !== 1 ? "s" : ""} `;
    }
    return result;
  }
}

// export const GetFormattedAmount = (amount, decimal) => {
//   let formattedAmount = "";
//   if (!Number.isNaN(amount)) {
//     amount = Number(amount).toFixed(decimal);
//     formattedAmount = new Intl.NumberFormat("en-IN", {
//       style: "currency",
//       currency: "INR",
//       minimumFractionDigits: decimal,
//     }).format(amount);

//     // Add a space between the currency symbol and the amount
//     formattedAmount = formattedAmount?.replace("₹", "₹ ");
//   }
//   return formattedAmount;
// };

export const GetFormattedAmount = (amount, decimal) => {
  let formattedAmount = "";
  if (!Number.isNaN(amount)) {
    amount = Number(amount).toFixed(decimal);
    formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "decimal",
      minimumFractionDigits: decimal,
    }).format(amount);

    // Append the currency symbol after the amount with a space
    formattedAmount = `${formattedAmount} ₹`;
  }
  return formattedAmount;
};

export const GetAmountFormats = (amount, decimal) => {
  let formattedAmount = "";
  if (!Number.isNaN(amount)) {
    amount = Number(amount).toFixed(decimal);
    formattedAmount = new Intl.NumberFormat("en-IN", {
      style: "decimal",
      minimumFractionDigits: decimal,
    }).format(amount);

    // Append the currency symbol after the amount with a space
    formattedAmount = `${formattedAmount}`;
  }
  return formattedAmount;
};

export const formatDuration = (totalTrackedSeconds) => {
  const hours = Math.floor(totalTrackedSeconds / 3600);
  const minutes = Math.floor((totalTrackedSeconds % 3600) / 60);
  const seconds = totalTrackedSeconds % 60;

  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${hours} hours `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}m `;
  }
  if (seconds > 0 || formattedTime === "") {
    formattedTime += `${seconds}s`;
  }

  return formattedTime.trim();
};

export const getHoursOnly = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  let formattedTime = "";

  if (hours > 0) {
    if (minutes > 30) {
      hours++;
    }
    formattedTime += `${hours} hours `;
  } else {
    if (minutes > 0) {
      formattedTime += `${minutes} m `;
    } else {
      if (seconds > 0) {
        formattedTime += `${seconds}s`;
      }
    }
  }

  return formattedTime == "" ? 0 : formattedTime.trim();
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const extractImageUrlAndConvertItInBase64 = (htmlString) => {
  const extractImageUrl = () => {
    const regex = /<img\s+src="([^"]+)"/i;
    const match = htmlString.match(regex);
    return match ? match[1] : null;
  };

  return new Promise(async (resolve, reject) => {
    const url = extractImageUrl(htmlString);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve({
          [url]: reader.result,
        });
      reader.onerror = () => reject("Failed to convert image to Base64");
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  });
};

export const SmartWatch = ({ startTime }) => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getTimeDifference = (start) => {
    if (currentTime?.isBefore(start) || currentTime?.isSame(start)) {
      return "00s";
    }
    const duration = moment.duration(currentTime.diff(start));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const pad = (value) => (value < 10 ? `0${value}` : value);

    if (hours > 0) {
      return `${pad(hours)}h : ${pad(minutes)}m : ${pad(seconds)}s`;
    } else if (minutes > 0) {
      return `${pad(minutes)}m : ${pad(seconds)}s`;
    } else {
      return `${pad(seconds)}s`;
    }
  };

  return (
    <div>
      {getTimeDifference(moment(startTime).format("YYYY-MM-DD HH:mm:ss"))}
    </div>
  );
};

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};
